<template>
  <div
    class="container-fluid tab-container text-center border-bottom"
    v-if="!isSearchPage && !isHideTab"
  >
    <div class="container">
      <div class="row">
        <div
          class="col-12 col-xl-12 d-inline-flex align-items-center tab-content justify-content-center"
        >
          <router-link
            class="tab"
            :to="item.path"
            v-for="item in routerProps"
            :key="item.name"
          >
            {{ item.name }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, computed } from 'vue'
import { useRoute } from 'vue-router'
defineProps({
  routerProps: {
    type: Array,
    default: () => []
  }
})
const route = useRoute()

// isSearchPage 主要为了配合搜索结果页，但是需要隐藏二级导航的其他内容
//
const isSearchPage = computed(() => route.meta.isSearchPage)
const isHideTab = computed(() => route.meta.hideTab)
</script>
<style lang="stylus" scoped>
.tab-container
  padding 0 !important
  // height 115px
  .tab-content
    height 115px
    @media screen and (max-width:768px) {
      height 80px
    }
  .tab
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333
    padding 10px 23px
    margin 0 15px
    text-decoration none
    @media screen and (max-width:1200px) {
      font-size 20px
    }
    @media screen and (max-width:993px) {
      font-size 14px
      margin 0 10px
      padding 10px 5px
    }
    &.router-link-active
      background #209BD7
      border-radius: 5px;
      color #fff
      position relative
      &::after
        content ''
        height 0
        width 0
        bottom -25px
        left 50%
        transform translateX(-50%)
        position absolute
        border-top 8px solid  #209BD7
        border-left 9px solid  transparent
        border-right 9px solid  transparent
        border-bottom 18px solid transparent
</style>
