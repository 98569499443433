<!-- 华都学院 -->
<template>
  <div class="intoHuadu-container">
    <navSecondPageVue
      type="HuaduCollege"
      v-if="isResultPage"
    ></navSecondPageVue>

    <Tabs :routerProps="routerProps" v-if="showTab && isResultPage"></Tabs>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" @hideTab="hideTab"></component>
      </keep-alive>
    </router-view>
  </div>
</template>
<script setup>
import { ref, watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import navSecondPageVue from '../components/navSecondPage.vue'
import Tabs from '../components/Tabs.vue'
const routerProps = [
  {
    name: '学院简介',
    path: '/HuaduCollege/College'
  },

  {
    name: '培训动态',
    path: '/HuaduCollege/Train'
  }
]
const showTab = ref(true)

const route = useRoute()
const hideTab = () => {
  showTab.value = false
}
watch(
  () => route.fullPath,
  () => {
    if (route.meta.hideTab) {
      return
    }
    showTab.value = true
  }
)
const isResultPage = computed(() => {
  // 路由meta 定义是搜索结果列表 就不显示二级导航
  if (route.meta.isSearchPage) {
    return false
  } else {
    return true
  }
})
</script>
<style lang="stylus" scoped></style>
