<template>
  <div
    class="nav-second-page container-fluid text-center"
    :style="'background:url('+ bg +');'"
    v-if="!isSearchPage"
  >
    <div class="text-wrap d-flex flex-column justify-content-center">
      <h4>{{ title }}</h4>
      <p>{{ enTitle }}</p>
    </div>
    <div class="nav-bar">
      <router-link to="/Index" custom v-slot="{ navigate }">
        <span @click="navigate" style="cursor: pointer">首页</span>
      </router-link>
      <span>></span>
      <router-link :to="path " custom v-slot="{ navigate }">
        <span style="cursor: pointer" @click="navigate">{{ title }}</span>
      </router-link>
      <span v-if="route.meta.cnName">></span>
      <span v-if="route.meta.cnName">{{ route.meta.cnName }}</span>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, defineProps, computed } from 'vue'
import { useRoute } from 'vue-router'
import { getZlmbanner } from '@/server/zlmbanner.js'

const bgArr = ref(null)
const props = defineProps({
  type: {
    type: String
  }
})
onMounted(async () => {
  const result = await getZlmbanner()
  bgArr.value = {
    IntoHuadu: {
      url: result[0].image,
      title: result[0].title,
      enTitle: result[0].subtitle,
      path: '/InToHuadu'
    },
    BusinessSection: {
      url: result[1].image,
      title: result[1].title,
      enTitle: result[1].subtitle,
      path: '/BusinessSection'
    },
    NewsCenter: {
      url: result[2].image,
      title: result[2].title,
      enTitle: result[2].subtitle,
      path: '/NewsCenter'
    },
    VideoCenter: {
      url: result[3].image,
      title: result[3].title,
      enTitle: result[3].subtitle,
      path: '/VideoCenter'
    },
    HuaduCollege: {
      url: result[4].image,
      title: result[4].title,
      enTitle: result[4].subtitle,
      path: '/HuaduCollege'
    },
    JoinHuadu: {
      url: result[5].image,
      title: result[5].title,
      enTitle: result[5].subtitle,
      path: '/JoinHuadu'
    }
  }
})

const bg = computed(() => bgArr?.value ? bgArr.value[props.type]?.url : '')
const title = computed(() => bgArr?.value ? bgArr.value[props.type]?.title : '')
const enTitle = computed(() => bgArr?.value ? bgArr.value[props.type]?.enTitle : '')
const path = computed(() => bgArr?.value ? bgArr.value[props.type]?.path : '')

const route = useRoute()

// isSearchPage 主要为了配合搜索结果页，但是需要隐藏二级导航的其他内容
// 在router.js内，,meta 规定了isSearchPage
const isSearchPage = computed(() => route.meta.isSearchPage)

// ....
</script>
<style lang="stylus" scoped>
.nav-second-page
  color #fff
  background-position center center
  background-size cover
  padding 0 !important
  .text-wrap
    height 300px
    @media screen and (max-width 768px) {
      height 150px
    }
    h4
      font-size: 49px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color #fff
      margin-bottom 20px
      @media screen and (max-width 768px) {
        font-size: 36px;
      }
    p
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      letter-spacing 8px
  .nav-bar
    height 50px
    line-height 50px
    background rgba(32, 155, 215, .4)
    font-size 12px
    span
      margin-right 10px
</style>
